import { initializeApp } from "firebase/app";
import { getValue, getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 500;

const getShowcase = async () => {
  await fetchAndActivate(remoteConfig);
  const showcaseRemoteConfig = getValue(remoteConfig, "vitrine");
  const showcaseJsonString = showcaseRemoteConfig.asString()
  const showcase = JSON.parse(showcaseJsonString)

  const storage = getStorage(app);

  for (const product of showcase) {
    const storageRef = ref(storage, product.image);
    const downloadUrl = await getDownloadURL(storageRef)

    product.imageDownloadUrl = downloadUrl
  }
  
  return showcase;
};

export { getShowcase };
