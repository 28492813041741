<template>
  <div class="static">
    <nav class="bg-blue-900 w-screen">
      <div class="flex flex-wrap max-w-screen-xl items-center justify-between mx-auto p-1">
        <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img :src="require('@/assets/logo_mgtratorpecas_branco.png')" class="md:h-full md:w-52"
            alt="MG Trator Logo" />
        </a>
        <button data-collapse-toggle="navbar-default" type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-gray-400 focus:outline-none focus:ring-2 focus:gray-400"
          aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div class="hidden w-full md:block md:w-auto px-4" id="navbar-default">
          <ul
            class="font-black text-xl flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-blue-900 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-blue-900">
            <li>
              <a href="#inicio"
                class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-200 md:p-0"
                aria-current="page">Inicio</a>
            </li>
            <li>
              <a href="#produtos"
                class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-400 md:p-0 md:dark:hover:bg-transparent">Produtos</a>
            </li>
            <li>
              <a href="#informacoes"
                class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Informações</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped></style>
