<template>
  <section id="inicio">
    <div class="brand-session py-8 text-center">
      <div class="logo-container flex flex-col md:flex-row justify-around items-center space-y-4 md:space-y-0">
        <img src="../assets/logo-volvo-group.svg" alt="Logo da Volvo Group" class="h-12 md:h-16">
        <img src="../assets/caterpillar-2.svg" alt="Logo da Caterpillar" class="h-10 md:h-16">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BrandSession",
};
</script>

<style scoped>
.brand-session {
  @apply container mx-auto max-w-screen-lg;
}
</style>
