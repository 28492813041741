<template class="bg-gray-50 py-8 antialiased dark:bg-gray-900 md:py-12">
  <section id="produtos">
  <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
    <!-- Heading & Filters -->
    <div class="mb-4 items-end justify-center space-y-4 sm:flex sm:space-y-0 md:mb-8">
      <div>
        <h1 class="mt-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Produtos importados diretamente das fábricas</h1>
        <div class="flex items-center space-x-4">
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>

export default {
  name: "ShowCase",
};
</script>
