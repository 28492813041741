<template>
  <div class="w-full relative">
    <div>
      <NavBar />
    </div>
    <div>
      <HomePage />
    </div>
    <div>
      <BrandSession />
    </div>
    <hr />
    <div class="max-w-screen-xl items-center justify-between mx-auto">
      <ShowCase />
      <div
        class="flex flex-wrap max-w-screen-xl items-center justify-between mx-auto"
      >
        <Suspense>
          <CardSession />
        </Suspense>
      </div>
    </div>
    <div class="inset-x-0 bottom-0 h-16 justify-center items-center">
      <FooterSession />
    </div>
  </div>
</template>

<script>
import HomePage from "../src/views/Home.vue";
import NavBar from "./views/Navbar.vue";
import FooterSession from "./views/FooterSession.vue";
import ShowCase from "./views/ShowCase.vue";
import CardSession from "./views/CardSession.vue";

import { onMounted } from "vue";
import { initFlowbite } from "flowbite";
import BrandSession from "./views/BrandSession.vue";

export default {
  name: "App",
  components: {
    NavBar,
    HomePage,
    BrandSession,
    ShowCase,
    FooterSession,
    CardSession,
  },
  setup() {
    onMounted(() => {
      initFlowbite();
    });
  },
};
</script>

<style></style>
