<template>
  <div>
    <div class="p-4 mb-4 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div
        v-for="product in paginatedShowcase"
        :key="product.id"
        class="max-w-sm bg-slate-50 border border-gray-200 rounded-lg shadow-md flex flex-col justify-between"
      >
        <div class="flex justify-center">
          <img class="p-2" :src="product.imageDownloadUrl" alt="" />
        </div>
        <div class="p-5 flex-grow">
          <hr class="h-2" />
          <a href="#">
            <h5
              class="mb-2 text-2xl font-normal tracking-tight text-gray-900 hover:underline min-h-[3rem]"
            >
              {{ product.title }}
            </h5>
          </a>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {{ product.id }}
          </p>
        </div>
        <div class="p-5">
          <div class="grid grid-cols-2 gap-2">
            <a
              class="inline-flex text-center justify-center font-semibold p-2 bg-[#25d366] text-white rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
              href="https://api.whatsapp.com/send/?phone=558581866717&text&type=phone_number&app_absent=0"
              target="_blank"
            >
              Whatsapp
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 mb-6">
      <button
        v-for="page in totalPages"
        :key="page"
        @click="goToPage(page)"
        :class="{'bg-blue-600 text-white': currentPage === page, 'bg-gray-200': currentPage !== page}"
        class="px-4 py-2 rounded-lg"
      >
        {{ page }}
      </button>
    </div>
  </div>
</template>


<script>
import { getShowcase } from "@/services/firebase";
import { ref, computed } from "vue";

export default {
  name: "CardSession",
  async setup() {
    const showcase = ref([]);
    showcase.value = await getShowcase();

    const currentPage = ref(1);
    const itemsPerPage = ref(4);

    const paginatedShowcase = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return showcase.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(showcase.value.length / itemsPerPage.value);
    });

    function goToPage(page) {
      currentPage.value = page;
    }

    return {
      showcase,
      paginatedShowcase,
      currentPage,
      itemsPerPage,
      totalPages,
      goToPage
    };
  },
};

</script>

<style scoped></style>
