<template>
  <section id="informacoes">
    <footer class="bg-blue-900">
      <div class="mx-auto w-full max-w-screen-xl py-6 lg:py-8">
        <div class="md:flex md:justify-between p-2">
          <div>
            <a href="/" class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
              <img :src="require('@/assets/logo_mgtratorpecas_branco.png')" class="p-4" alt="MG Trator Logo" />
            </a>
          </div>
          <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3">
            <div class="p-2">
              <h1 class="mb-6 text-sm font-semibold text-base text-white uppercase">
                Contatos
              </h1>
              <ul class="flex flex-wrap items-center mb-6 text-white">
                <li class="mb-4">
                  <span>
                    <h2 class="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                      </svg>
                      E-mail:
                    </h2>
                    comercial@mgtratorpecas.com.br
                  </span>
                  <div class="py-4"></div>
                  <span>
                    <h2 class="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                      </svg>

                      Celular/Whatsapp:
                    </h2>
                    <a class="hover:underline"
                      href="https://api.whatsapp.com/send/?phone=558581866717&text&type=phone_number&app_absent=0"
                      target="_blank">+55 (85) 98186-6717</a>
                    <div>
                      <a class="hover:underline"
                        href="https://api.whatsapp.com/send/?phone=558581866717&text&type=phone_number&app_absent=0"
                        target="_blank">+55 (98) 99176-8988</a>
                    </div>
                  </span>
                </li>
              </ul>
            </div>
            <div class="p-2">
              <h1 class="mb-6 text-sm font-semibold text-base text-white uppercase">
                Redes Sociais
              </h1>
              <ul class="flex flex-wrap items-center mb-6 text-white">
                <li class="mb-4">
                  <a href="https://www.instagram.com/mg_tratorpecas_?igsh=MWlubzdmaHlldXY0Zw==" target="_blank"
                    class="mr-4 hover:underline md:mr-6 flex">
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      fill="none" viewBox="0 0 24 24">
                      <path fill="currentColor" fill-rule="evenodd"
                        d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                        clip-rule="evenodd" />
                    </svg>
                    Instagram</a>
                </li>
              </ul>
            </div>
            <div class="p-2">
              <h1 class="mb-6 text-sm font-semibold text-base text-white uppercase">
                Loja física
              </h1>
              <ul class="flex flex-wrap items-center mb-6 text-white">
                <li class="mb-4">
                  <a href="https://www.google.com.br/maps/place/Av.+Guajajaras,+402+-+Tirirical,+S%C3%A3o+Lu%C3%ADs+-+MA,+65055-660/@-2.5683037,-44.2416539,15z/data=!4m6!3m5!1s0x7f69aa091a87eb9:0xbcbf251967031947!8m2!3d-2.5684371!4d-44.2319269!16s%2Fg%2F11gfgnz3pz?entry=ttu"
                    target="_blank" class="mr-4 hover:underline md:mr-6 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    Av. Guajajaras, 402 - Tirirical</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-300 sm:mx-auto lg:my-8" />
        <div class="sm:flex sm:items-center justify-center">
          <span class="text-sm text-gray-400 sm:text-center">© 2024 <a href="/"
              class="hover:underline">MGTratorPecas™</a>. Todos
            os direitos reservados.</span>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "FooterSession",
};
</script>

<style scoped></style>
